import { render, staticRenderFns } from "./BotLearningItemForm.vue?vue&type=template&id=318a0d69&scoped=true&"
import script from "./BotLearningItemForm.vue?vue&type=script&lang=js&"
export * from "./BotLearningItemForm.vue?vue&type=script&lang=js&"
import style0 from "./BotLearningItemForm.vue?vue&type=style&index=0&id=318a0d69&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318a0d69",
  null
  
)

export default component.exports