<template>
  <div>
    <div
      v-if="itemId && uiFlags.isFetchingItem"
      class="flex flex-col items-center text-base justify-center modal-content w-full"
    >
      <spinner />
      <span>{{ $t('BOT_LEARNING_PAGE.EDIT.LOADING_MESSAGE') }}</span>
    </div>
    <form
      v-else
      class="flex flex-col modal-content w-full gap-1"
      @keydown.enter.prevent
      @submit.prevent="submit()"
    >
      <label
        v-if="!onlyAnswer"
        class="w-full"
        :class="{ error: $v.title.$error }"
      >
        {{ $t('BOT_LEARNING_PAGE.FORM.TITLE.LABEL') }}
        <input
          v-model="title"
          type="text"
          :placeholder="$t('BOT_LEARNING_PAGE.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <span v-if="$v.title.$error" class="message">
          {{ $t('BOT_LEARNING_PAGE.FORM.TITLE.ERROR') }}
        </span>
      </label>
      <section v-if="!onlyAnswer" class="max-h-[500px] overflow-y-auto">
        <label class="w-full">
          {{ $t('BOT_LEARNING_PAGE.FORM.QUESTIONS.LABEL') }}
        </label>
        <div
          class="w-full p-2 bg-slate-25 dark:bg-slate-700 rounded-lg border border-solid border-slate-50 dark:border-slate-700"
        >
          <label v-if="emptyQuestionList" class="w-full message">
            {{ $t('BOT_LEARNING_PAGE.FORM.QUESTIONS.ERROR') }}
          </label>
          <div
            v-for="(question, index) in questionList"
            :key="index"
            class="p-1 mt-2 bg-slate-50 dark:bg-slate-700 rounded-lg border border-solid border-slate-50 dark:border-slate-700"
          >
            <div class="flex items-center">
              <span class="me-1">{{ index + 1 }}- </span>
              <input
                v-model.trim="question.text"
                type="text"
                class="question-input"
                :placeholder="
                  $t('BOT_LEARNING_PAGE.FORM.QUESTIONS.PLACEHOLDER')
                "
                @blur="checkEmptyQuestionList"
              />
              <woot-button
                v-tooltip="$t('NOTES.CONTENT_HEADER.DELETE')"
                type="button"
                variant="clear"
                size="medium"
                icon="dismiss"
                color-scheme="secondary"
                class="bg-inherit"
                @click="removeQuestion(index)"
              />
            </div>
          </div>
          <woot-button
            type="button"
            class="mt-4"
            color-scheme="success"
            icon="add"
            size="small"
            @click="addQuestion"
          >
            {{ $t('BOT_LEARNING_PAGE.FORM.QUESTIONS.ADD_NEW_QUESTION') }}
          </woot-button>
        </div>
      </section>
      <label class="w-full" :class="{ error: $v.answer.$error }">
        {{ $t('BOT_LEARNING_PAGE.FORM.ANSWER.LABEL') }}
        <textarea
          v-model="answer"
          class="h-[6rem]"
          :placeholder="$t('BOT_LEARNING_PAGE.FORM.ANSWER.PLACEHOLDER')"
          @blur="$v.answer.$touch"
        />
        <span v-if="$v.answer.$error" class="message">
          {{ $t('BOT_LEARNING_PAGE.FORM.ANSWER.ERROR') }}
        </span>
      </label>
      <label v-if="!onlyAnswer" class="flex items-center w-full">
        {{ $t('BOT_LEARNING_PAGE.FORM.STATUS') }}
        <woot-switch :value="active" class="mx-4" @input="toggleActivity" />
      </label>
      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-disabled="$v.answer.$error || uiFlags.isCreatingItem"
          :is-loading="uiFlags.isCreatingItem"
        >
          {{ $t(`${actionText}.SUBMIT_BUTTON`) }}
        </woot-button>
        <woot-button
          type="button"
          class="button clear"
          @click.prevent="onClose"
        >
          {{ $t('BOT_LEARNING_PAGE.FORM.CANCEL_BUTTON') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  mixins: [alertMixin],
  props: {
    tabId: {
      type: String,
      default: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    onlyAnswer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questionList: [{ text: '' }],
      emptyQuestionList: false,
      answer: '',
      active: true,
      title: '',
    };
  },
  validations: {
    answer: { required },
    title: { required },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'botLearnings/getUIFlags',
      itemRecords: 'botLearnings/getBotLearningItems',
    }),
    actionText() {
      return `BOT_LEARNING_PAGE.${this.itemId ? 'EDIT' : 'CREATE'}`;
    },
  },
  mounted() {
    if (this.itemId) {
      this.fetchItem();
    }
  },
  methods: {
    checkEmptyQuestionList() {
      if (this.questionList.every(q => q)) {
        this.emptyQuestionList = false;
        return false;
      }
      this.emptyQuestionList = true;
      return true;
    },
    addQuestion() {
      if (!this.checkEmptyQuestionList()) {
        this.questionList.push({ text: '' });
      }
    },
    removeQuestion(event) {
      this.questionList.splice(event, 1);
      this.checkEmptyQuestionList();
    },
    toggleActivity() {
      this.active = !this.active;
    },
    onClose() {
      this.$emit('cancel');
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const method = this.itemId ? 'EDIT' : 'CREATE';
      try {
        const action = `botLearnings/${
          this.itemId ? 'editItem' : 'createItem'
        }`;
        await this.$store.dispatch(action, {
          answer: this.answer,
          item_id: this.itemId,
          only_answer: this.onlyAnswer,
          ...(!this.onlyAnswer && {
            questions: this.questionList,
            disabled: !this.active,
            tab_id: this.tabId,
            title: this.title,
          }),
        });
        if (!this.itemId) this.$emit('create');
        this.showAlert(this.$t(`BOT_LEARNING_PAGE.${method}.SUCCESS_MESSAGE`));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message || this.$t(`BOT_LEARNING_PAGE.${method}.ERROR_MESSAGE`);
        this.showAlert(errorMessage);
      }
    },
    async fetchItem() {
      try {
        const result = await this.$store.dispatch('botLearnings/getItem', {
          itemId: this.itemId,
        });
        this.questionList = JSON.parse(result.questions);
        this.answer = result.answer;
        this.active = !result.disabled;
        this.title = result.title;
      } catch (error) {
        const errorMessage =
          error.message || this.$t('BOT_LEARNING_PAGE.EDIT.ERROR_RECEIVE_ITEM');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.question-input {
  margin-bottom: 0 !important;
}

.message {
  @apply text-sm text-red-500 dark:text-red-500;
}
</style>
